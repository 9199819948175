
// // Custom Theming for Angular Material
@use '~/node_modules/@angular/material' as mat;
@include mat.core();
$RealFreedom-theme: mat.define-light-theme((
    color: (
        primary: mat.define-palette(mat.$indigo-palette),
        accent: mat.define-palette(mat.$pink-palette, A200, A100, A400),
        warn: mat.define-palette(mat.$red-palette),
    )
));
@include mat.all-component-themes($RealFreedom-theme);

@import './variables.scss';

// Styling
.main-modal {
    filter: drop-shadow(0 10px 30px rgba(0,0,0, .5));
    // width: 400px;
    // max-width: 90vw !important;

    @media(max-width:767px){
        width: 100vw !important;
        height: 100% !important;
        max-width: 100vw !important;
        max-height: 100vh !important;

        .mat-dialog-container {
            border-radius: 0 !important;
        }
    }
    // Desktop styling
    @media(min-width:768px){
        width: 500px;
        height: 500px;
        max-width: 90vw !important;
        max-height: 100vh !important;
    }
    
    .mat-dialog-container {
        border-radius: 20px;
        color: var(--text-color);
        background-color: var(--background-color);
        background: radial-gradient(ellipse, var(--background-color) 50%, var(--accent-color) 100%); 
        // outline: 2px solid $border-light !important;
    }
}
.min-modal {
    filter: drop-shadow(0 10px 30px rgba(0,0,0, .5));
    
    .mat-dialog-container {
        border-radius: 20px;
        color: var(--text-color);
        background-color: var(--background-color);
        background: radial-gradient(ellipse, var(--background-color) 50%, var(--accent-color) 100%); 
        // outline: 2px solid $border-light !important;
    }
}
.width-modal {
    filter: drop-shadow(0 10px 30px rgba(0,0,0, .5));
    width: 400px !important;
    max-width: 95vw !important;

    .mat-dialog-container {
        border-radius: 20px;
        color: var(--text-color);
        background-color: var(--background-color);
        background: radial-gradient(ellipse, var(--background-color) 50%, var(--accent-color) 100%); 
        // outline: 2px solid $border-light !important;
    }
}
.width-modal-2 {
    filter: drop-shadow(0 10px 30px rgba(0,0,0, .5));
    width: 485px !important;
    max-width: 95vw !important;

    .mat-dialog-container {
        border-radius: 20px;
        color: var(--text-color);
        background-color: var(--background-color);
        background: radial-gradient(ellipse, var(--background-color) 50%, var(--accent-color) 100%); 
        // outline: 2px solid $border-light !important;
    }
}
.secondary-modal {
    filter: drop-shadow(0 10px 30px rgba(0,0,0, .5));

    @media(max-width:767px){
        width: 100vw !important;
        height: 100% !important;
        max-width: 100vw !important;
        max-height: 100vh !important;

        .mat-dialog-container {
            border-radius: 0 !important;
        }
    }
    // Desktop styling
    @media(min-width:768px){
        width: 425px;
        height: max-content;
        max-width: 90vw !important;
        max-height: 100vh !important;
    }
    
    .mat-dialog-container {
        padding: 0;
        border-radius: 20px;
        color: var(--text-color);
        background-color: var(--background-color);
        background: radial-gradient(ellipse, var(--background-color) 50%, var(--accent-color) 100%); 
        // outline: 2px solid $border-light !important;
    }
}

.mat-dialog-container {
    border-radius: 20px;
    color: var(--text-color);
    background-color: var(--background-color);
    // background: radial-gradient(ellipse, var(--background-color) 50%, $dark-blue 100%); 
    // outline: 2px solid $border-light;
    // outline: 2px solid $border !important;
    // border-radius: 20px !important;
    // background-color: radial-gradient(var(--background-color), $dark-blue) !important;
    // background: radial-gradient(var(--background-color), $dark-blue);

    // color: var(--text-color);

    // filter: drop-shadow(0 10px 30px rgba(0,0,0, .5));

    // background: $blue;
    // color: var(--text-color);
    // border-radius: 20px !important;
}

.loading-overlay {
    width: max-content;

    .mat-dialog-container {
        background: var(--background-color);
        color: var(--text-color);
        border-radius: 20px;
        padding: 1.5rem !important;

        .message {
            margin-left: .5rem;
        }
    }
}
.alert-modal {
    width: 400px;

    .mat-dialog-container {
        background: var(--background-color);
        color: var(--text-color) !important;
        padding: 1.5rem !important;
        // border-radius: 20px !important;
    }
}

.cdk-overlay-dark-backdrop {
    background: rgba(5, 8, 26, 0.6) !important;
}

mat-form-field {
    i {
        color: var(--accent-color) !important;
    }
}


.mat-snack-bar-container {
    border-radius: 10px !important;
    padding: 18px 20px !important;
    background: var(--background-color) !important;
    color: var(--text-color);
    
    .mat-button {
        color: var(--text-color);
        font-weight: 800;
        text-transform: uppercase;
    }
}

.game-modal {
    // Mobile Styling
    @media(max-width:991px){
        height: 100%;
        width: 100vw !important;
        max-width: 100vw !important;
    }
    // Desktop styling
    @media(min-width:992px){
        height: calc(100% - 70px) !important;
        width: calc(100vw - 70px) !important;
        max-width: unset !important;
    }

    .mat-dialog-container {
        padding : 0 !important;
    }
}

.inventory-modal {
    @media(max-width:767px){
        width: 100vw !important;
        height: 100% !important;
        max-width: 100vw !important;
        max-height: 100vh !important;
    }
    // Desktop styling
    @media(min-width:768px){
        // width: 70vw;
        // height: 650px;
        min-width: 38vw;
        max-width: 95vw !important;
        max-height: 95vh !important;
    }
}